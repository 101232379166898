function getColumns(type) {
  return [
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: '150',
      show: true,
      disabled: true
    },
    {
      prop: 'styleName',
      label: 'Style',
      show: true,
      width: '120',
      disabled: true
    },
    {
      prop: 'color',
      label: 'Color',
      show: true,
      disabled: true,
      width: '120'
    },
    {
      prop: 'size',
      label: 'Size',
      show: true,
      disabled: true,
      width: '120'
    },
    {
      prop: 'categoryNameI18',
      label: '类别',
      show: true,
      width: '160'
    },
    {
      prop: 'brandName',
      label: '品牌',
      show: true,
      width: '120'
    },
    {
      prop: 'skuName',
      label: 'SKU',
      show: true,
      width: '120'
    },
    {
      prop: 'shopName',
      label: '平台/站点/店铺',
      show: true,
      width: '150'
    },
    {
      prop: 'seasonName',
      label: '季节',
      show: true,
      width: '120'
    },
    {
      prop: 'positionName',
      label: '定位',
      show: true,
      width: '120'
    },
    {
      prop: 'negativeCashFlag',
      label: '是否负现金流',
      show: true,
      width: '130'
    },
    {
      prop: 'versionCode',
      label: '销售预测版本',
      show: true,
      width: '120'
    },
    {
      prop: type == 1 ? 'overseasReplTurnover' : 'cnGenReplTurnover',
      label: 'TurnOver',
      show: true,
      width: '120'
    },
    {
      prop: 'mps',
      label: 'MPS',
      show: true,
      width: '120'
    },
    {
      prop: 'rps',
      label: 'RPS',
      show: true,
      width: '120'
    },
    {
      prop: 'recentSale',
      label: '近30天销量',
      show: true,
      width: '120'
    },
    {
      prop: 'recentReturn',
      label: '近30天销售退货',
      show: true,
      width: '120'
    },
    {
      prop: 'safeStockDays',
      label: '安全库存天数',
      show: true,
      width: '120'
    },

    {
      prop: 'avgReturnRate',
      label: '平均退货率',
      show: true,
      width: '120'
    },
    {
      prop: 'hisSales',
      label: '历史销量',
      show: true,
      width: '120'
    },
    {
      prop: 'predictSales',
      label: '预测销量',
      show: true,
      isColumn: true,
      disabled: true,
      width: '120'
    },
    {
      prop: 'predictReturn',
      label: '销售退货',
      show: true,
      isColumn: true,
      disabled: true,
      width: '120'
    },
    {
      prop: 'netShippingDemand',
      label: '净发运需求',
      show: true,
      isColumn: true,
      disabled: true,
      width: '120'
    },
    {
      prop: 'shippingPlan',
      label: '发运计划',
      show: true,
      isColumn: true,
      disabled: true,
      width: '120'
    }
  ]
}

export { getColumns }
