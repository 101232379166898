<template>
  <div>
    <el-dialog
      title="自定义显示列项"
      :visible.sync="dialogVisible"
      width="800px"
      center
      :close-on-click-modal="false"
    >
      <vxe-table
        ref="xTable"
        border
        height="800"
        row-key
        class="sortable-row-demo"
        :data="tableData"
        align="center"
        :checkbox-config="checkBoxConfig"
        :row-config="{
          keyField: 'prop',
        }"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckAllboxChange"
      >
        <vxe-column type="checkbox" width="80" />
        <vxe-column type="seq" width="60" />
        <vxe-table-column field="label" title="列名" />
        <vxe-table-column title="拖动调整顺序" width="120">
          <template v-slot>
            <span class="drag-btn">
              <i class="vxe-icon--menu" />
            </span>
          </template>
        </vxe-table-column>
      </vxe-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="text" @click="reset">恢复默认</el-button>
        <div>
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import { deepClone } from '@/utils';
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
    findLength: {
      type: Number,
      default: 100,
    },
    data: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      checkBoxConfig: {
        checkMethod: ({ row }) => {
          return !row.disabled;
          // return true;
        },
        // 开启保留翻页选中
        reserve: false,
      },
    
    };
  },
  computed: {
    dialogVisible: {
      get() {
        this.tableData = deepClone(this.data);
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    // this.tableData = window.MOCK_DATA_LIST.slice(0, 6);
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.rowDrop();
      }
      this.setCheckbox()
    },
    data(val) {
   
    },
    initData(val) {
      this.initTableData = val

    },
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  },
  methods: {
    setCheckbox() {
      this.$nextTick(() => {
        this.$refs.xTable.setCheckboxRow(
          this.tableData.filter((v) => v.show),
          true
        );
      });
    },
    reset() {
      this.tableData = deepClone(this.initTableData);
      this.setCheckbox()
    },
    handleCheckboxChange({checked, row}) {
      row && Object.assign(row, {show: checked});
      // const $table = this.$refs.xTable;
      // const selectRecords = $table?.getCheckboxRecords();
    },
    handleCheckAllboxChange({ checked }) {
      this.tableData.map(v=> !(v.disabled) && (v.show = checked))
    },
    submit() {
      this.dialogVisible = false;
      this.$emit("changColumn", this.tableData);
    },
    //表格拖动排序方法
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable;
        this.sortable = Sortable.create(
          xTable.$el.querySelector(".body--wrapper>.vxe-table--body tbody"),
          {
            handle: ".drag-btn",
            onEnd: ({ newIndex, oldIndex }) => {
              const currRow = this.tableData.splice(oldIndex, 1)[0];
              this.tableData.splice(newIndex, 0, currRow);
            },
          }
        );
      });
    },
    disableCheck(params) {
      // console.log("%c Line:162 🍏 params", "color:#fca650", params);
      // 根据项目的属性来决定是否禁用勾选
      // return params.row.status === 'inactive';
    }
  },
};
</script>

<style lang="scss" scope>
.sortable-row-demo .drag-btn {
  cursor: move;
  font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
  background-color: #dfecfb;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}
</style>
