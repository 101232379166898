export function handelplatSiteShop(arr){
  const platSiteShopList = []
  Array.isArray(arr) && arr.map(list => {
    const [ plat, site, shop ] = list
    const [ platformId, platformName ] = plat.split('&&')
    const [ siteId, siteCode ] = site.split('&&')
    const [ shopId, shopCode ] = shop.split('&&')
    platSiteShopList.push({
      platformId, platformName,
      siteId, siteCode,
      shopId, shopCode
    })
  })
  return platSiteShopList
}