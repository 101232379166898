<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="海外调拨发运" name="1" />
      <el-tab-pane label="国内普船发运" name="2" />
    </el-tabs>

    <el-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      label-width="134px"
      style="display: flex; flex-wrap: wrap; margin-top: 15px"
    >
      <div class="el-lt" style="width: 97%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="10">
            <el-form-item label="发运计划起止时间" prop="planStartTime">
              <el-date-picker
                v-if="form.dimensions == 3"
                v-model="form.planStartTime"
                type="daterange"
                align="right"
                value-format="yyyyMMdd"
                format="yyyyMMdd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerDateOptions"
                style="width: 100%"
                @change="changeTime"
              />
              <WeekDatePicker
                v-if="form.dimensions == 2"
                :week="defaultWeek"
                @endWeek="getEndWeek"
                @startWeek="getStartWeek"
              />
              <el-date-picker
                v-if="form.dimensions == 1"
                v-model="form.planStartTime"
                type="monthrange"
                align="right"
                value-format="yyyyMM"
                format="yyyyMM"
                unlink-panels
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                :picker-options="pickerOptionsMon"
                style="width: 100%"
                @blur="handleMonChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              label="销售预测版本"
              prop="versionCode"
              :class="$i18n.locale"
            >
              <el-select
                v-model="form.versionCode"
                class="style_w100"
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in dsbVersionOptions"
                  :key="item.versionCode"
                  :label="item.versionCode"
                  :value="item.versionCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="备货渠道" :class="$i18n.locale">
              <el-select
                v-model="form.stockChannelIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.styleIdList"
                oninput="(/\s+/g, '')"
                value-key="id"
                clearable
                filterable
                class="style_w100"
                @change="
                  (e) => {
                    this.colorOptions = [];
                    this.sizeOptions = [];
                    if (e) {
                      _queryColorList(e);
                      _querySizeList(e);
                    }
                    form.colorList = '';
                    form.sizeList = '';
                  }
                "
              >
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Color">
              <el-select
                v-model="form.colorList"
                class="style_w100"
                clearable
                filterable
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Size">
              <el-select
                v-model="form.sizeList"
                class="style_w100"
                clearable
                filterable
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.size"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="品牌" :class="$i18n.locale">
              <el-select
                v-model="form.brandIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.id"
                  :label="item.brandName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sku" :class="$i18n.locale">
              <el-input
                v-model="form.sku"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
              <el-cascader
                v-model="form.platSiteShopList"
                clearable
                collapse-tags
                filterable
                :options="alldatas"
                class="style_w100"
                :props="{
                  value: 'value',
                  label: 'dropDownName',
                  children: 'dropDownList',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="季节" :class="$i18n.locale">
              <el-select
                v-model="form.seasonIdList"
                class="style_w100"
                clearable
                filterable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in _getAllCommodityDict('SEASON')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="定位" :class="$i18n.locale">
              <Select
                v-model="form.positionIdList"
                style="width: 100%"
                api-key="position"
                clearable
                filterable
                collapse-tags
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类别" :class="$i18n.locale">
              <el-cascader
                v-model="form.lastCategoryIdList"
                clearable
                collapse-tags
                filterable
                :options="basecategorydatas"
                separator=">"
                :filter-method="dataFilter"
                class="style_w100"
                :props="{
                  value: 'id',
                  label: 'categoryName',
                  children: 'sonCategory',
                  multiple: true,
                }"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 3%; text-align: right">
        <el-button type="text" @click="showRow = !showRow">{{
          showRow ? $t("page.hide") : $t("page.expand")
        }}</el-button>
      </el-row>
      <div class="el-rt header_btn">
        <el-form-item
          label="数据查看维度"
          :class="$i18n.locale"
          style="margin-bottom: 0"
        >
          <el-radio-group v-model="form.dimensions" @change="dimensionChange">
            <el-radio :label="1">月</el-radio>
            <el-radio :label="2">周</el-radio>
            <el-radio :label="3">天</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="activeName === '2'"
          label="供应约束周期(天):"
          prop="constraintCycle"
        >
          <el-input-number
            v-model="form.constraintCycle"
            :min="0"
            :max="180"
            :controls="false"
            :precision="0"
            @blur="handleConstraintCycleBlur"
          />
        </el-form-item>
        <div class="row-center">
          <el-button
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="queryClick(1)"
          >{{ $t("page.search") }}</el-button>
          <el-button :loading="loading" @click="resetClick()">{{
            $t("page.reset")
          }}</el-button>
          <el-button
            v-permission="'exportA'"
            type="primary"
            class="ml-4"
            :loading="loading"
            @click="_exportDetail"
          >导出</el-button>
          <el-button
            v-permission="'selfShow'"
            icon="el-icon-setting"
            size="small"
            type="primary"
            plain
            @click="visibleDrag = true"
          >
            自定义显示列
          </el-button>
        </div>
      </div>
    </el-form>
    <DragTable
      v-model="visibleDrag"
      :data="showColumn"
      :init-data="initData"
      @changColumn="setColumns"
    />

    <vxe-table
      ref="multipleTable"
      :loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      style="width: 100%"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="600px"
      align="center"
      @checkbox-all="selectChangeEvent"
      @checkbox-change="selectChangeEvent"
    >
      <vxe-column type="checkbox" width="60" />
      <vxe-column type="seq" title="序号" width="50" />
      <template v-for="item in showColumn.filter((v) => v.show)">
        <vxe-column
          v-if="!item.isColumn"
          :key="item.prop"
          :field="item.prop"
          :title="item.label"
          :min-width="item.width || 120"
          align="center"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop === 'shopName'"
            >{{ scope.row.platformName }}/{{ scope.row.siteName }}/{{
              scope.row.shopName
            }}</span>
            <span v-else-if="item.prop == 'categoryNameI18'">{{
              Array.isArray(scope.row[item.prop]) &&
                scope.row[item.prop].join(">")
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </vxe-column>
        <template v-else-if="showLabel[item.prop]">
          <vxe-column
            v-for="(list) in Object.keys(showLabel[item.prop])"
            :key="`${list} && ${item.prop}`"
            :field="list"
            :title="list"
            :min-width="item.width || 120"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row[item.prop] && scope.row[item.prop][list]
              }}</span>
            </template>
          </vxe-column>
        </template>
      </template>
    </vxe-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="_purchasePage" />
    </div>
  </div>
</template>
<script>
import { getBasecategory } from '@/api/configuration'
import {
  trackDimensionEvent,
  trackSiteSearch,
  handleBreadTitle,
  trackExportEvent,
  trackPaginationEvent
} from '@/utils/monitor'
import { handleMonitorParams } from '@/utils'

import {
  queryStyleList,
  querySizeList,
  queryColorList,
  getplatformSiteShop,
  getDsbVersion,
  StockChannelList
} from '@/api/scm-api'
import DragTable from '@/components/DragTable'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryBrandList } from '@/api/listSelection'
import { getListDsbSku, exportRequirePlan } from '@/api/supply'
import dayjs from 'dayjs'
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import { getColumns } from './coumns'
import WeekDatePicker from '@/components/WeekDatePicker'
import { handelplatSiteShop } from '../planUtils'
import { handlePageParams, deepClone } from '@/utils'
import { omit } from 'lodash'

export default {
  components: {
    Paging,
    DragTable,
    WeekDatePicker,
    Select
  },
  mixins: [commodityInfoDict],
  data() {
    const start = dayjs().format('YYYYMM')
    const end = dayjs().add(12, 'month').format('YYYYMM')
    return {
      currentDay: 60,
      multipleSelection: [],
      showColumn: [],
      alldatas: [],
      minMon: dayjs().format('YYYYMM'),
      minDate: dayjs().format('YYYYMMDD'),
      initData: [],
      defaultWeek: [
        dayjs().startOf('week').add(1, 'day'),
        dayjs().add(26, 'week').startOf('week').add(1, 'day')
      ],
      basecategorydatas: [],
      brandOptions: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      StockChannelOptions: [],
      dsbVersionOptions: [],
      form: {
        constraintCycle: 60,
        planStartTime: [start, end],
        versionCode: '', // 版本编号
        stockChannelIdList: [], // 备货渠道
        styleIdList: '',
        colorList: '',
        sizeList: '',
        brandIdList: [],
        sku: '',
        seasonIdList: [],
        positionIdList: [],
        lastCategoryIdList: [],
        dimensions: 1,
        platSiteShopList: []
      },
      showRow: false,
      visibleDrag: false,
      TableLoading: false,
      loading: false,
      activeName: '1',
      rules: {
        versionCode: [{ required: true, message: '请选择', trigger: 'change' }],
        planStartTime: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        constraintCycle: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableDatas: [],
      showLabel: {},
      confirmHead: [],
      handelrender: true
    }
  },
  computed: {
    pickerOptionsMon() {
      const that = this
      return {
        onPick: ({ minDate, maxDate }) => {
          console.log('时间', minDate, maxDate)
          this.minMon = minDate
        },
        disabledDate: (time) => {
          // 查询时间跨度为12个月
          const startDate = that.minMon
          const diffTime = dayjs(time.getTime()).format('YYYYMM')
          const nowTime = dayjs(startDate).diff(diffTime, 'month')
          return nowTime > 12 || nowTime < -12
        },
        shortcuts: [
          {
            text: '本月',
            onClick(picker) {
              const time = dayjs().format('YYYYMM')
              picker.$emit('pick', [time, time])
            }
          },
          {
            text: '未来六个月',
            onClick(picker) {
              picker.$emit('pick', [
                dayjs().format('YYYYMM'),
                dayjs().add(6, 'month').format('YYYYMM')
              ])
            }
          },
          {
            text: '未来十二个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setMonth(end.getMonth() + 12)
              picker.$emit('pick', [
                dayjs().format('YYYYMM'),
                dayjs().add(12, 'month').format('YYYYMM')
              ])
            }
          }
        ]
      }
    },
    pickerDateOptions() {
      const that = this
      return {
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate
        },
        disabledDate(time) {
          const nowTime = that.minDate
          const diffTime = dayjs(nowTime).diff(
            dayjs(time.getTime()).format('YYYYMMDD'),
            'day'
          )
          return diffTime > 90 || diffTime < -90
        },
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const start = dayjs().format('YYYYMMDD')
              picker.$emit('pick', [
                start,
                dayjs().add(7, 'day').format('YYYYMMDD')
              ])
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const start = dayjs().format('YYYYMMDD')
              picker.$emit('pick', [
                start,
                dayjs().add(30, 'day').format('YYYYMMDD')
              ])
            }
          },
          {
            text: '最近90天',
            onClick(picker) {
              const start = dayjs().format('YYYYMMDD')
              picker.$emit('pick', [
                start,
                dayjs().add(90, 'day').format('YYYYMMDD')
              ])
            }
          }
        ]
      }
    },
    queryParams() {
      return handlePageParams({
        formData:
          this.activeName === '2'
            ? this.form
            : omit(this.form, ['constraintCycle']),
        processedParameter: {
          lastCategoryIdList: ['lastCategoryIdList'],
          timeField: {
            planStartTime: ['planStart', 'planEnd']
          },
          toArrayList: ['colorList', 'sizeList', 'styleIdList']
        },
        extraParams: {
          type: this.activeName,
          platSiteShopList: handelplatSiteShop(this.form.platSiteShopList)
        }
      })
    }
  },
  mounted() {
    this._queryStyleList()
    this._brandList()
    this._listPlatformSiteShop()
    this._getBasecategory()
    this._getDsbVersion()

    this._getColumns()
    this._StockChannelList()
  },
  methods: {
    handleConstraintCycleBlur(val) {
      this.$confirm('更改供应约束周期后，将重新生成发运计划，是否继续？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.currentDay = val.target.value
        this.queryClick(1)
      }).catch(() => {
        this.form.constraintCycle = this.currentDay
      })
    },
    dataFilter(node, val) {
      if (
        !!~node.text.indexOf(val) ||
        !!~node.text.toUpperCase().indexOf(val.toUpperCase())
      ) {
        return true
      }
    },
    selectChangeEvent({ records }) {
      this.multipleSelection = records
    },
    getEndWeek(val) {
      val && (this.form.planStartTime = [this.form.planStartTime[0], val])
    },
    getStartWeek(val) {
      val && (this.form.planStartTime = [val, this.form.planStartTime[1]])
    },
    _getColumns() {
      const data = getColumns(this.activeName)
      this.initData = data
      const result = localStorage.getItem(`activeName_${this.activeName}`)

      this.showColumn = [] // 防止频繁切换表头显示错误
      this.$nextTick(() => {
        this.showColumn = (result && JSON.parse(result)) || deepClone(data)
      })
    },
    handleColumns() {
      // this.tableDatas
    },
    setColumns(data) {
      this.showColumn = [] // 防止频繁切换表头显示错误
      localStorage.setItem(
        `activeName_${this.activeName}`,
        JSON.stringify(data)
      )
      this.$nextTick(() => {
        this.showColumn = data
      })
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    // size
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    // brand
    async _brandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    // 销售预测版本下拉
    async _getDsbVersion() {
      try {
        this.loading = true
        this.TableLoading = true
        const { datas } = await getDsbVersion()
        if (!Array.isArray(datas)) return
        this.dsbVersionOptions = datas
        this.form.versionCode = datas[datas.length - 1]?.versionCode

        this.queryClick(1)
      } catch (err) {
        this.loading = false
        this.TableLoading = false
      }
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    async getList(flag) {},
    handleMonChange(value) {
      console.log('%c Line:542 🍭 value', 'color:#b03734', value)
    },
    changeTime(value) {
      console.log('%c Line:581 🌰 value', 'color:#6ec1c2', value)
    },
    queryClick(flag) {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          try {
            this.loading = true
            this.TableLoading = true
            flag && flag === 1 ? (this.pager.current = 1) : ''
            const {
              datas: { pager, records }
            } = await getListDsbSku(this.queryParams, this.pager)
            this.pager = pager
            this.tableDatas = records
            if (records.length > 0) {
              this.showLabel = records[0]
            }
            this.loading = false
            this.TableLoading = false
            trackSiteSearch(
              JSON.stringify(handleMonitorParams(this.queryParams)),
              handleBreadTitle(this.$route) +
                `${this.activeName === '1' ? '海外调拨发运' : '国内普船发运'}`,
              this.pager.size
            )
            this.multipleSelection = []
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
            this.TableLoading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async _exportDetail(query) {
      const { activeName } = this
      const params = {
        ...this.queryParams,
        idList: this.multipleSelection?.map((v) => v.id) || []
      }
      this.activeName === '1' ? delete params.constraintCycle : ''
      const { msg, code } = await exportRequirePlan({
        params: JSON.stringify(params),
        exportKey: 'scm_dsb_export'
      })
      if (code === 0) {
        this.$notify({
          message: msg,
          type: 'success'
        })
      }
      trackExportEvent(
        handleBreadTitle(this.$route) +
          `${activeName === '1' ? '海外调拨发运' : '国内普船发运'}`
      )
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.form.versionCode =
        this.dsbVersionOptions[this.dsbVersionOptions.length - 1]?.versionCode
      this.queryClick(1)
    },
    dimensionChange(e) {
      if (e == 3) {
        const start = dayjs().format('YYYYMMDD')
        this.form.planStartTime = [
          start,
          dayjs().add(90, 'day').format('YYYYMMDD')
        ]
      } else if (e === 2) {
        const formatStartWeek = String(
          dayjs(this.defaultWeek[0]).week()
        ).padStart(2, '0')
        const formatEndWeek = String(
          dayjs(this.defaultWeek[1]).week()
        ).padStart(2, '0')
        this.form.planStartTime = [
          `${dayjs(this.defaultWeek[0]).isoWeekYear()}${formatStartWeek}`,
          `${dayjs(this.defaultWeek[1]).isoWeekYear()}${formatEndWeek}`
        ]
      } else {
        const start = dayjs().format('YYYYMM')
        const end = dayjs().add(12, 'month').format('YYYYMM')
        this.form.planStartTime = [start, end]
      }
      trackDimensionEvent(
        handleBreadTitle(this.$route) +
          `${this.activeName === '1' ? '海外调拨发运' : '国内普船发运'}切换至${
            this.form.dimensions
          }`
      )

      this.queryClick()
    },

    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    async _getBasecategory() {
      const { datas } = await getBasecategory()
      // this.getDataTree(datas);
      this.basecategorydatas = datas
    },
    handleClick(tab, event) {
      this.resetClick()
      this._getColumns()
    },
    async _purchasePage(pagers) {
      this.pager = pagers
      trackPaginationEvent(
        handleBreadTitle(this.$route) +
          `${this.activeName === '1' ? '海外调拨发运' : '国内普船发运'}-条目数${
            pagers.size
          }-当前页${pagers.current}`
      )
      this.queryClick()
    },
    confirmdata(val) {
      this.confirmHead = val
      // console.log(val);
    },
    renderHide(val) {
      this.handelrender = val
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    }
  }
}
</script>
<style lang="scss" scope>
.style_w100 {
  width: 100%;
}
.header_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
</style>
